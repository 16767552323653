import React, { useState } from "react";
import axios from "axios";

import { css } from "@emotion/core";
import "../../styles/components/_stripeElements.scss";
import { baseURL } from "../../app.config";

import {
  CardElement,
  injectStripe,
  CardCVCElement,
  CardNumberElement,
  CardExpiryElement,
} from "react-stripe-elements";
import { ScaleLoader } from "react-spinners";

import {submitAttemptToHubspot, submitSuccessToHubspot} from "../../services/hubspot/submitFormToHubspot";

const createOptions = () => {
  return {
    iconStyle: "solid",
    style: {
      base: {
        color: "#424770",
        fontFamily: "Source Code Pro, monospace",
        "::placeholder": {
          color: "#aab7c4",
        },
        fontSize: "3rem",
      },
      invalid: {
        color: "#9e2146",
      },
    },
  };
};

const override = css`
  margin: -2rem 0 0 -2rem;
  position: absolute;
  top: 50%;
  left: 50%;
`;

const StripeElements = (props) => {
  const [loading, setLoading] = useState(false);
  const {
    stripe,
    cvr,
    success,
    failed,
    email,
    phone,
    name,
    userCvr,
    allowMarketing,
  } = props;
  const submit = async () => {

    //User attempets to buy a report - send "attemped"
    submitAttemptToHubspot(props.name, email, phone, cvr)

    setLoading(true);

    
    // Creates paymentMethod or error
    const { paymentMethod, error } = await stripe.createPaymentMethod(
      "card",
      {}
    );
    if (error) {
      failed("error");
    } else {
      // sends the paymentMethod ID
      // ProffBackend APIController line 193
      const res = await sendReport(undefined, paymentMethod.id);
      handleServerResponse(res.data);
    }
  };

  const handleServerResponse = async (response) => {
    if (response.error) {
      // Show error from server on payment form
      setLoading(false);
      failed(response.error);
    } else if (response.requires_action) {
      // Use Stripe.js to handle the required card action
      const {
        error: errorAction,
        paymentIntent,
      } = await stripe.handleCardAction(response.payment_intent_client_secret);
      if (errorAction) {
        setLoading(false);
        failed(errorAction);
      } else {
        // The card action has been handled
        // The PaymentIntent can be confirmed again on the server
        const res = await sendReport(paymentIntent);
        setLoading(false);
        success("Payment complete");
      }
    } else {
      setLoading(false);
      success("Payment complete");

      // Sending user form to Subspot
      submitSuccessToHubspot(props.name, email, phone, cvr);
    }
  };

  const sendReport = (paymentIntent, paymentMethod) => {
    return axios.post(`${baseURL}/api/validate_payment`, {
      cvr: cvr,
      email: email,
      name: name,
      phone: phone,
      userCvr: userCvr,
      country: "dk",
      allowMarketing,
      payment_intent_id: paymentIntent,
      payment_method_id: paymentMethod,
    });
  };

  return (
    <div className="form">
      <div className="card">
        <img className="risikaLogo" src="/static/logo_risika_new.png" />
        <CardElement className="cardElement" />
        <img className="stripeLogo" src="/static/powered_by_stripe.png" />
      </div>
      <div style={{ position: "relative" }}>
        <button
          style={
            loading
              ? { opacity: "0.7", backgroundColor: "gray" }
              : { opacity: "1" }
          }
          onClick={submit}
          className="button btn btn-primary btn-inverse pay-button"
        >
          Køb rapport 99,- (ekskl. moms)
        </button>
        <ScaleLoader
          css={override}
          sizeUnit={"rem"}
          size={5}
          color={"#FFCC89"}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default injectStripe(StripeElements);
