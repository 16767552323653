import React from "react";

// React Accessible Accortion components
// https://github.com/springload/react-accessible-accordion
import {
  Accordion,
  AccordionItem,
  AccordionItemTitle,
  AccordionItemBody,
} from "react-accessible-accordion";

import CookieBanner from "react-cookie-banner";

import Blockquote from "../components/Blockquote.js";
import ContentBlock from "../components/ContentBlock.js";
import Partnership from "../components/Partnership.js";
import Testimonials from "../components/Testimonials.js";

const styles = {
  banner: {
    display: "flex",
    height: "auto",
    padding: "10px",
    background: "#245157 url(/static/cookie.png) 20px 50% no-repeat",
    backgroundSize: "30px 30px",
    fontWeight: 600,
    position: "fixed",
    zIndex: 1,
    borderRadius: 5,
    bottom: 5,
    right: 5,
    width: "calc(100% - 10px)",
    maxWidth: "800px",
  },
  button: {
    padding: "2px 10px",
    borderRadius: 4,
    lineHeight: "32px",
    backgroundColor: "#FFCC89",
    color: "#0A373D",
    fontSize: "14px",
    fontWeight: 600,
    opacity: 1,
    right: "3rem",
    marginTop: -18,
  },
  message: {
    display: "block",
    padding: "9px 67px",
    lineHeight: 1.3,
    textAlign: "left",
    maxWidth: "90%",
    color: "white",
  },
  link: {
    textDecoration: "none",
    fontWeight: "bold",
  },
};

const message =
  "Risika uses cookies to offer users a better purchasing experience. By continuing to browse the site you're agreeing to our use of cookies.";

document.cookie.split(";").forEach(function (c) {
  document.cookie = c
    .replace(/^ +/, "")
    .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
});

export default (props) => (
  <div className="page-wrapper">
    <CookieBanner
      styles={styles}
      message={message}
      link={
        <a href="http://risika.com/">More information on our use of cookies</a>
      }
      buttonMessage="Close"
      dismissOnScroll={false}
      dismissOnClick={true}
      // onAccept={() => setState({ accepted: true })}
    />

    <Partnership />

    <div className="max-width">

      <ContentBlock
        left={
          <div>
            <h1>Undgå dårlige handler</h1>
            <p>
              Søg efter firmaet og få et grundigt kredittjek af din næste kunde,
              før du giver kreditten. Det sikrer dig mod tab og dårlige handler.
            </p>
          </div>
        }
        right={
          <div>
            <img src="/static/dummy-trendgraph.png" />
          </div>
        }
      />
      <Testimonials />

      <Blockquote>
        <p>Det tager kun et par minutter at spotte de dårlige betalere.</p>
      </Blockquote>

      <ContentBlock
        id="section-credit-rating"
        left={<h1>Kredit-vurderingen indeholder</h1>}
        right={
          <div className="centered">
            <Accordion accordion={false}>
              <AccordionItem expanded>
                <AccordionItemTitle>
                  <h3>Konkrete anbefalinger!</h3>
                  <span className="line"></span>
                  <span className="mui-container">
                    <i className="material-icons">add</i>
                  </span>
                </AccordionItemTitle>
                <AccordionItemBody>
                  <div className="risika-kreditscore">
                    <div className="risika-kreditscore__score">
                      <img src="/static/risika-kreditscore/Skjold3.png" />
                    </div>
                    <div className="risika-kreditscore__panels">
                      <div className="risika-kreditscore__panel">
                        <p>
                          <small>Anbefalet antal kreditdage</small>
                        </p>
                        <p>Maksimum 7 dage.</p>
                      </div>
                      <div className="risika-kreditscore__panel">
                        <p>
                          <small>Anbefalet kreditmaks</small>
                        </p>
                        <p>272.000 kr</p>
                      </div>
                    </div>
                  </div>
                  <p>
                    Din kunde får en let og forståelig kreditscore fra 1 til 10.
                    Det gør det let for dig at forstå, hvor sund en økonomi din
                    kunde har.
                  </p>
                  <p>
                    Du får konkrete anbefalinger til betalingsbetingelser og
                    hvor mange penge, du maksimalt bør have tilgode hos din
                    kunde.
                  </p>
                </AccordionItemBody>
              </AccordionItem>

              <AccordionItem>
                <AccordionItemTitle>
                  <h3>Kend dem, du handler med</h3>
                  <span className="line"></span>
                  <span className="mui-container">
                    <i className="material-icons">add</i>
                  </span>
                </AccordionItemTitle>
                <AccordionItemBody>
                  <p>
                    Vores kreditrapport giver dig et hurtigt og intuitivt
                    overblik over din kunder, leverandører og
                    samarbejdspartnere. Vi har samlet de informationer, der er
                    vigtige for at vurdere risikoen ved at foretage en handel
                    eller indgå et nyt samarbejde. Du finder blandt andet
                    informationer om:
                  </p>
                  <ul>
                    <li>
                      Har der været en revisor inden over det seneste regnskab?
                    </li>
                    <li>Er der konkursryttere i ejerkredsen?</li>
                    <li>
                      Er en stor del af egenkapitalen i immaterielle aktiver?
                    </li>
                    <li>
                      Har virksomheden været i en negativ udvikling de seneste
                      år?
                    </li>
                  </ul>
                </AccordionItemBody>
              </AccordionItem>

              <AccordionItem>
                <AccordionItemTitle>
                  <h3>Alle stamdata samlet et sted</h3>
                  <span className="line"></span>
                  <span className="mui-container">
                    <i className="material-icons">add</i>
                  </span>
                </AccordionItemTitle>
                <AccordionItemBody>
                  <p>
                    Det kan være svært at få det helhedsbillede, der gør jer
                    trygge ved samhandel. Med Risikas kreditrapport får I den
                    væsentlige information præsenteret, så I hurtigt og nemt får
                    et godt udgangspunkt for en tryg samhandel. Vores rapport
                    giver dig bl.a. informationer om:
                  </p>
                  <ul>
                    <li>Adresse og kontaktoplysninger</li>
                    <li>
                      Tegningsreglen. Dvs. hvem må underskrive aftaler for
                      selskabet?
                    </li>
                    <li>Virksomhedens direktion og ejerkredsen</li>
                    <li>Stiftelsesdato og branche</li>
                    <li>Antal ansatte</li>
                    <li>
                      Desuden finder du relevante regnskabs- og nøgletal med
                      tilhørende forklaringer
                    </li>
                  </ul>
                </AccordionItemBody>
              </AccordionItem>
            </Accordion>
          </div>
        }
      />

      <ContentBlock
        id="datatilsynet"
        center={
          <div className="centered">
            <img src="/static/logo_datatilsynet_no_crown.png" />
            <p>
              <small>
                Risika er godkendt af Datatilsynet som kreditoplysningsbureau.
              </small>
            </p>
          </div>
        }
      />
    </div>
  </div>
);
