import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Header from './components/Header.js';
import FooterTwo from './components/FooterTwo.js';
import PageBody from './components/PageBody.js';

import 'normalize.css/normalize.css';
import './styles/styles.scss';
import 'react-accessible-accordion/dist/fancy-example.css';

const App = () => (
  <div className="wrapper">
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Header}/>
        <Route component={Header}/>
      </Switch>
    </BrowserRouter>
    <PageBody/>
    <FooterTwo/>
  </div>
)

export default App