import React from "react"

const Input = (props) => {
  let classes = "form__control"
  if (props.underlined) classes += " form__control--underlined"
  if (props.inverse) classes += " form__control--inverse"
  return (
    <div className={props.classes ? props.classes : classes}>
      {props.label && <label>{props.label}</label>}
      <input
        name={props.name}
        type={props.type}
        placeholder={props.placeholder}
      />
      {props.icon && <i className="material-icons">{props.icon}</i>}
      {props?.validation?.length ? <p>{props.validation}</p> : null}
    </div>
  )
}

export default Input
