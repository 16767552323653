import React, { Component } from 'react';
import { BarLoader } from 'react-spinners';

export default class SearchResult extends Component {

  state = {
    loading: false
  }

  handleClick = () => {
    const { search } = this.props;
    const cvr = this.props.result.cvr || (search + '').replace(/\s/g, '');
    // console.log("SearchResult, handleClick, cvr:", cvr);
    if (!cvr) return;
    this.setState({ loading: true });
    setTimeout(() => this.props.onClick(cvr), 500);
  }

  render() {
    const { search } = this.props;
    const { cvr, name, location, score } = this.props.result;

    return (
      <li className="search-result">
        <a onClick={ this.handleClick }>
          {
            location
            ? <span className="search-result__name">{ name }<br/><small><i>{ location || " "}</i></small></span>
            : <span className="search-result__name">{ name }</span>
          }
          <span className="search-result__cvr"><span>{ cvr }</span></span>
        </a>
        <BarLoader
          loading={ this.state.loading }
        />
      </li>
    );
  }
}
