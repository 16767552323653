import React, { Component } from 'react';
import StripeCheckout from 'react-stripe-checkout';

import {
  updateLocalStorage
} from '../../utils.js';

import { stripeLiveKey, stripeTestKey } from '../../app.config.js';

const payment_server_URL = "/attemptcharge";
const currency = "DKK";

let stripeKey;
if (process.env.NODE_ENV.toLowerCase() === 'production') {
  stripeKey = stripeLiveKey;
} else {
  stripeKey = stripeTestKey;
}

function gtag_report_conversion(url) {
  var callback = function () {
    if (typeof(url) != 'undefined') {
      window.location = url;
    }
  };

  // gtag('event', 'conversion', {
  //   'send_to': 'AW-838537283/gvLeCLLU0JEBEMOg7I8D',
  //   'transaction_id': '',
  //   'event_callback': callback
  // });
  
  return false;
}

// console.log("NODE_ENV =", process.env.NODE_ENV);
// console.log('Stripe:', stripeKey.substring(3,7));

export default class Stripe extends Component {

  state = {
    paymentComplete: false
  }

  onToken = (token) => {
    const {
      cvr,
      user_cvr,
      user_name,
      user_email,
      user_phone,
      session
    } = this.props.data;

    const json = {
      token,
      cvr,
      session,
      user: {
        cvr: user_cvr,
        name: user_name,
        email: user_email,
        phone: user_phone
      }
    }

    fetch(payment_server_URL, {
      method: 'POST',
      dataType: 'json',
      headers: { "Content-Type": "application/json; charset=utf-8" },
      body: JSON.stringify(json)
    })
    .then(this.successPayment)
    .catch(this.failedPayment);
  }

  onOpened = (event) => {
    updateLocalStorage({
      status: 'open_skype_checkout'
    });
  }

  onClosed = (event) => {
    updateLocalStorage({
      status: 'close_skype_checkout'
    });
  }

  successPayment = (data) => {
    if (data && data.status == 404) {
      this.failedPayment("404 Not Found");
    } else {
      this.props.success();

      // Track purchase
      gtag_report_conversion('https://kredittjek.risika.com/')
    }
  }

  failedPayment = (err) => {
    this.props.failed(err);
  }

  render() {
    const {
      cvr,
      user_cvr,
      user_name,
      user_email,
      user_phone,
      isAllValidated
    } = this.props.data;

    return (
      <StripeCheckout
        token={ this.onToken }
        stripeKey={ stripeKey }
        name="Risika ApS"
        image="/static/risika-logo-for-stripe-checkout.png"
        className="abc"
        panelLabel="Køb"
        amount={ 99 * 1.25 }
        currency={ currency }
        locale="da"
        email={ user_email }
        allowRememberMe={ true }
        opened={ this.onOpened }
        closed={ this.onClosed }
      >
        <button className="btn btn-primary btn-inverse">
          Køb rapport 99,- (ekskl. moms)
        </button>
      </StripeCheckout>
    );
  }
}
