import React from "react";

const creaditReports = () => {
  return (
    <div style={styles.container}>
      {/* <div style={styles.gridContainer}>
        <h1 style={styles.title}> Kreditrapporter</h1>
        <div style={styles.textContetn}>
          Vores populære kreditrapporter, der er udviklet i samarbejde med
          Eniro/Proff, indeholder alt, hvad du skal bruge for at tjekke op på
          dine nuværende og potentielle kunder.
        </div>
      </div> */}
      <div><a href="https://risika.com/" style={styles.aboutRisika}>Lav automatiske kredittjek på risika.com</a></div>
      <img
        style={styles.image}
        src="/static/credit-report.webp"
        alt="Et billede af kreditrapporter "
      />
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: 'column',
    justifyContent: "space-between",
    alignItems: "center",
    'margin-bottom': '2rem',
  },
  gridContainer: {
    display: "grid",
    "grid-template-rows": "auto",
    "grid-gap": "1em",
  },
  image: {
    width: '40%',
    marginTop: '2rem',
  },
  title: {
    fontSize: "42px",
    fontWeight: 400,
    color: '#fff'
  },
  textContetn: {
    fontSize: "18px",
  },
  aboutRisika: {
    forntSize: '14px',
    textDecoration: 'none',
    borderBottom: '1px solid #FFCC89',
    cursor: 'pointer',
    color: '#fff'
  },
};
export default creaditReports;
