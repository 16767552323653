export const baseURL         = 'https://kredittjek.risika.com';
export const cvrAPI          = '/api/header?cvr=';
export const textAPI         = '/api/search?query=';

// export const baseURL         = 'http://localhost:8007';
// export const cvrAPI          = 'http://localhost:8007/api/header?cvr=';
// export const textAPI         = 'http://localhost:8007/api/search?query=';

export const localStorageKey = 'risika_proff_localStorage_key';

export const stripeLiveKey = 'pk_live_1d3u6rRpZN0CMChbKVuFabPT';
export const stripeTestKey = 'pk_test_m3PBNeMZF3UVtYnv1KHlrq5R';
