import React from 'react';
import { css } from '@emotion/core';
import SearchResult from './SearchResult.js';

export default ({ onPick, onClose, onSubmit, results, search }) => (
  <div id="search-results" className="message collapsible collapsible--inverse">
    <div className="collapsible__panel">
      <div className="collapsible__content">

        <div className="collapsible__body">
          <h2 className="inverse">
            <span className="search-label">Search results for</span>
            <span className="search-keyword">{ search }</span>
            <span className="search-new" onClick={ onClose }>New search</span>
          </h2>
          { results.length
            ? (results.map((item, key) => (
                <SearchResult
                  key={ key }
                  result={ item }
                  onClick={ onPick }
                />
              )))
            : <SearchResult
              key={ 0 }
              result={ { name: "Company name not found, search for CVR instead?"} }
              search={ search }
              onClick={ onPick }
            />
          }
        </div>
        <div className="collapsible__controls">
          <button className="btn btn-outlined btn-inverse" onClick={ onClose }>
            Ny søgning
          </button>
        </div>
      </div>
    </div>
  </div>
);
