import axios from "axios";

//Point to Kredittjek.risika.dk - attempt
const FORM_ID_ATTEMPT = "70468aa8-4a53-4d5a-9929-0f9cf84c9a5f";

//Points ot Kredittjek.risika.dk form on HubSpot, which keeps track of succes
const FORM_ID_SUCCESS = "f5b86b89-3611-4a6d-9242-c81f3711f1ca";

const PORTAL_ID = "5137178";
const FORM_SUBMISSION_URL = `https://api.hsforms.com/submissions/v3/integration/submit/${PORTAL_ID}`;

const getHubspotCookieValue = () => {
  const cookieWeCareAbout = "hubspotutk";

  let cookie;

  try {
    const maybeCookie = document.cookie
      .split("; ")
      .map((x) => x.split("="))
      .filter((x) => x[0] === cookieWeCareAbout);

    if (maybeCookie != null && maybeCookie.length > 0) {
      cookie = maybeCookie[0][1]; // Get the first cookie and take its value
    }
  } catch (e) {
    // no-op
  }

  return cookie;
};

const submitAttemptToHubspot = (name, email, phoneNumber, cvrNumber) =>
  submitFormToHubspot(name, email, phoneNumber, cvrNumber, FORM_ID_ATTEMPT);

const submitSuccessToHubspot = (name, email, phoneNumber, cvrNumber) =>
  submitFormToHubspot(name, email, phoneNumber, cvrNumber, FORM_ID_SUCCESS);

const submitFormToHubspot = (name, email, phoneNumber, cvrNumber, url) => {
  axios({
    method: "POST",
    url: `${FORM_SUBMISSION_URL}/${url}`,
    data: {
      fields: [
        {
          name: "firstname",
          value: name,
        },
        {
          name: "email",
          value: email,
        },
        {
          name: "phone",
          value: phoneNumber,
        },
        {
          name: "cvr",
          value: cvrNumber,
        },
      ],
      submitedAt: Date.now(),
      context: {
        hutk: getHubspotCookieValue,
        pageUri: "kredittjek.risika.com",
      },
    },
  }).catch((e) => {
    console.log(e);
  });
};

export { submitAttemptToHubspot, submitSuccessToHubspot };
