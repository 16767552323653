import React, { Component } from 'react';
import Button from '../Button.js';

export default (props) => (
  <div id="payment-complete" className="payment-feedback collapsible collapsible--inverse">
    <span className="line"></span>
    <div className="collapsible__panel">
      <div className="collapsible__content">

        <div className="collapsible__body">
          <h2>Tak for dit køb!</h2>
          <p>Din Risika Kreditrapport er ved at blive produceret og lander i din email inbox inden for få minutter.</p>
          <p>Hvis den ikke er dukket op inden for 5 minutter, så prøv at tjekke dit spam-filter.</p>
          <p>Du kan altid kontakte os på <a href="mailto:contact@risika.com">contact@risika.com</a>.</p>
        </div>

        <div className="collapsible__controls">
          <button className="btn btn-outlined btn-inverse" onClick={ props.onClose }>
            Ny søgning
          </button>
        </div>
      </div>
    </div>
  </div>
);
