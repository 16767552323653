import React from 'react';
import Collapsible from './Collapsible.js';

const sectionHeader = (
  <header className="full-width partnership__content">
    <a className="partnership__logo-link" href="https://risika.com"><img src="/static/logo_risika_new.png"/></a>
    <span className="line"></span>
    <h2 className="centered">Nyt samarbejde</h2>
    <span className="line"></span>
    <a className="partnership__logo-link" href="https://proff.dk"><img src="/static/logo_proff.png"/></a>
  </header>
);

const collapsibleContent = (
  [
    <div key="1" className="flex-column">
      <p>Proff™ og Risika har i august 2018 indgået et nyt samarbejde om salg af virksomhedsrapporter via proff.dk. Proff.dk er danmarks førende virksomhedsdatabase med dybdegående informationer på mere end 800.000 danske virksomheder.</p>
    </div>,
    <div key="2" className="flex-column">
      <p>Proff™ anvendes til at finde potentielle kunder, leverandører eller partnere indenfor bestemte brancher samt til at validere økonomien i bestemte virksomheder. Igennem proff.dk kan alle danske virksomheder nu få let adgang til vigtige kreditoplysninger via Risikas virksomhedsrapporter. Virksomhedsrapporten kan købes for alle danske selskaber (A/S, K/S, ApS & IVS), der minimum har aflagt ét årsregnskab.</p>
    </div>,
    <div key="3" className="flex-column">
      <p>Risika er ny dansk aktør på markedet for kreditvurderinger af virksomheder. Vi er dedikeret til at skabe sikkerhed i samhandlen mellem danske virksomheder via retvisende og præcise kreditanbefalinger.</p>
    </div>
  ]
);

class Partnership extends React.Component {
  state = {
    isModalOpen: false
  }
  openModal = (event) => {
    event.preventDefault();
    this.setState({ isModalOpen: true });
  }
  closeModal = (event) => {
    event.preventDefault();
    this.setState({ isModalOpen: false });
  }
  render() {
    const fadeOut = this.state.isModalOpen ? " fade-out" : "";
    const linkClasses = "centered partnership__link" + fadeOut;
    return (
      <section className="partnership">
        <div className="max-width">{ sectionHeader }</div>
        <div className={ linkClasses }>
          <p>Læs om <strong><a href="#" onClick={ this.openModal }>samarbejdet</a></strong></p>
        </div>
        <Collapsible
          isOpen={ this.state.isModalOpen }
          onRequestClose={ this.closeModal }
        >
          { collapsibleContent }
        </Collapsible>
      </section>
    );
  }
}

export default Partnership;
