import React, { Component } from 'react';
import queryString from 'query-string';
import uuid from 'uuid';
import { BarLoader } from 'react-spinners';

import Button from './Button.js';
import SearchResults from './SearchResults.js';
import Input from './Input.js';
import CheckoutForm from './Stripe/CheckoutFormWithValidation.js';
import PaymentComplete from './Stripe/PaymentComplete.js';
import PaymentFailed from './Stripe/PaymentFailed.js';

import {
    cvrAPI,
    textAPI
} from '../app.config.js';

import {
  updateLocalStorage,
  clearLocalStorage,
  validateLocalStorage,
  validateCVR
} from '../utils.js';

const formatCVR = (cvr) => `${cvr[0]}${cvr[1]} ${cvr[2]}${cvr[3]} ${cvr[4]}${cvr[5]} ${cvr[6]}${cvr[7]}`;

// Possible states

export default class Header extends Component {

  constructor(props) {

    super(props);

    validateLocalStorage();

    const { cvr, pnum } = queryString.parse(props.location.search);
    const state = 'loading';

    const session = updateLocalStorage({
      cvr,
      state
    });

    this.state = {
      companyName: '',
      cvr,
      error: '',
      pnum,
      purchasable: false,
      results: [],
      search: '',
      session,
      state
    }
  }

  componentDidMount() {
    const { cvr, state } = this.state;
    if (cvr) {
      this.setState({ state });
      this.fetchCVR(cvr);
    }
    else {
      this.setState({ state: 'new_search' });
    }
  }

  componentDidUpdate() {
    // console.log("----");
    // console.log('Current state:', this.state.state);
    // console.log("----");
  }

  fetchCVR = (cvr) => {
    return fetch(cvrAPI + cvr)
      .then(response => {
        return response.json();
      })
      .then(json => {
        // console.log('json:', json);
        if (json && json.payload) {
          // console.log('json.payload:', json.payload);
          const { status, msg } = json.status;
          const { companyName, cvr, creationYear, purchasable } = json.payload;
          let state;
          if (status == 'SUCCESS') state = 'purchase_report'; // || 'show_result' ?
          else state = 'show_error_message';
          const session = updateLocalStorage({
            cvr,
            purchasable,
            state
          });
          this.setState({
            companyName,
            creationYear,
            cvr,
            error: status,
            msg,
            purchasable,
            session,
            state
          });
        } else {
          this.setState({
            msg: "Invalid CVR",
            state: 'show_error_message'
          });
          // this.forceUpdate();
          throw new Error("!!");
        }
      })
      .catch(error => {
        console.log("fetchCVR:", error);
        throw new Error(error);
      });
  }

  fetchSearch = (text) => {
    this.setState({ results: [] });
    return fetch(textAPI + text)
      .then(response => response.json())
      .then(json => {
        if (json && json.payload) {
          // console.table(json.payload);
          const state = 'show_search_results'
          const session = updateLocalStorage({ state });
          // console.log("Searching for...", text);
          this.setState({
            session,
            state,
            results: json.payload
          });
        } else {
          throw new Error("???");
        }
      })
      .catch(error => {
        console.log("fetchSearch:", error);
        throw new Error(error);
      });
  }

  handleSubmitSearch = (event, value) => {
    event.preventDefault();
    // console.log("handleSubmitSearch, event:", value);
    const search = value || this.state.search;
    // console.log("handleSubmitSearch, search:", search);
    if (validateCVR(search)) {
      const cvr = (search + '').replace(/\s/g, '');
      const session = updateLocalStorage({ state: 'loading' });
      this.setState({ session, state: 'loading' });
      this.setState({ cvr });
      // console.log("Fetching for...", cvr);
      this.fetchCVR(cvr);
    } else {
      // console.log("Invalid CVR, start search by name:", search);
      this.setState({ cvr: null });
      this.fetchSearch(search);
    }
  }

  handleInputSearchChange = (event) => {
    event.preventDefault();
    const { value } = event.target;
    this.setState({ cvr: null, search: value });
    // console.log("handleInputSearchChange, event.target.value:", value);
    if (value.length < 3) this.setState({ state: 'new_search' });
    // else this.handleSubmitSearch(event, value);
    else this.fetchSearch(value);
  }

  handleNewSearch = (event) => {
    const session = updateLocalStorage({ id: uuid(), state: 'new_search' }); // reset id, initiate a new session
    this.setState({ session, state: 'new_search' });
  }

  handleCloseConfirmation = () => {
    const session = updateLocalStorage({ id: uuid(), state: 'new_search' }); // reset id, initiate a new session
    this.setState({ session, state: 'new_search' });
  }

  handleClosePanel = () => {
    const session = updateLocalStorage({ state: 'show_result' });
    this.setState({ session, state: 'show_result' });
  }

  handleClosePurchaseReport = () => {
    const state = 'new_search'
    const session = updateLocalStorage({ state });
    this.setState({ session, state });
  }

  handleCloseSearch = () => {
    const state = 'purchase_report';
    const session = updateLocalStorage({ state });
    this.setState({ session, state });
  }

  handleBuyReport = () => {
    const session = updateLocalStorage({ state: 'purchase_report' });
    this.setState({ session, state: 'purchase_report' });
  }

  handleSuccessfulPayment = () => {
    const session = updateLocalStorage({ state: 'payment_complete' });
    this.setState({ session, state: 'payment_complete' });
    clearLocalStorage();
  }

  handleFailedPayment = (err) => {
    const session = updateLocalStorage({ state: 'payment_failed' });
    this.setState({ session, state: 'payment_failed' });
    clearLocalStorage();
  }

  handlePick = (cvr) => {
    this.fetchCVR(cvr);
  }

  inputSearch = (
    <form onSubmit={ this.handleSubmitSearch }>
      <div className="form__control">
        <label>Søg virksomhed</label>
        <input
          type="text"
          placeholder="Eks. firmanavn eller cvr"
          // onChange={ (event) => {
          //   this.setState({ search: event.target.value });
          // }}
          onChange={ this.handleInputSearchChange }
        />
        <i
          className="material-icons flip clickable"
          onClick={ this.handleSubmitSearch }
          >search</i>
      </div>
    </form>
  )

  reportPrice = (
    <div className="topbar__product-price">
      <label>Pris i DKK ekskl. Moms</label>
      {/* <h1>249,-</h1> */}
      <h1>99,-</h1>
    </div>
  )

  controlClosePurchaseReport = (
    <span
      className="mui-container"
      onClick={ this.handleClosePurchaseReport }
    ><i className="material-icons">clear</i></span>
  )

  controlCloseSearch = (
    <span
      className="mui-container"
      onClick={ this.handleCloseSearch }
    ><i className="material-icons">clear</i></span>
  )

  controlBuyReport = (
    <button
      className="btn btn-inverse"
      onClick={ this.handleBuyReport }
    >Køb vurderingen <strong>99,-</strong></button>
  )

  controlNewSearch = (
    <button
      className="btn btn-inverse btn-outlined"
      onClick={ this.handleNewSearch }
    >Ny søgning</button>
  )

  render() {
    const {
      companyName,
      cvr,
      creationYear,
      error,
      msg,
      purchasable,
      results,
      search,
      session,
      state
    } = this.state;
    const formattedCVR = cvr && formatCVR(cvr);
    const headerClasses = `header__container state--${state}`;
    const topbarClasses = `topbar`;

    return (
      <div className={ headerClasses }>
        <div className="collapsible__background"></div>
        <nav className={ topbarClasses }>
          <div className="topbar__brand site-logo">
            <a href="https://risika.com"><img src="/static/risika-logo-2019-inverse-800.png"/></a>
          </div>
          <div className="topbar__body">
            <div className="topbar__title">
              {
                state == 'new_search'
                ? <h2>Få nemt og hurtigt indblik</h2>
                : <h2>Få vores kreditvurdering</h2>
              }
            </div>
            <div className="topbar__content">
              { state == 'loading' && <div className="topbar__content__feedback"><h3><i>Søger på... { formattedCVR }</i></h3></div> }
              { (state == 'new_search'  || state == 'show_search_results') && this.inputSearch }
              { state == 'purchase_report' && <div className="topbar__content__column-data"><label>Navn</label><h3>{ companyName }</h3></div> }
              { state == 'purchase_report' && <div className="topbar__content__column-data"><label>CVR</label><h3>{ formattedCVR }</h3></div> }
              { state == 'purchase_report' && <div className="topbar__content__column-data"><label>Stiftet</label><h3>{ creationYear }</h3></div> }
              { state == 'show_error_message' && <div className="topbar__content__feedback"><p>{ msg }</p></div> }
            </div>
          </div>
          { state == 'purchase_report' && this.reportPrice }
          <div className="topbar__controls">
            { state == 'show_result' && this.controlBuyReport }
            { state == 'show_result' && this.controlNewSearch }
            { (state == 'purchase_report' || state == 'show_error_message') && this.controlClosePurchaseReport }
            { state == 'new_search' && purchasable && this.controlCloseSearch }
          </div>
        </nav>

        {
          state == 'loading' || state == 'fetching'
          ? <div className="loader-override">
              <BarLoader
                loading={ true }
              />
            </div>
          : null
        }

        {
          this.state.state === 'purchase_report'
          && <CheckoutForm
            cvr={ cvr }
            session={ session }
            success={ this.handleSuccessfulPayment }
            failed={ this.handleFailedPayment }
          />
        }
        { state === 'payment_complete'   && <PaymentComplete onClose={ this.handleCloseConfirmation }/> }
        { state === 'payment_failed'     && <PaymentFailed onClose={ this.handleCloseConfirmation } message={ "Vi kan ikke godkende din betalingsmetode " }/> }
        { state === 'show_search_results' && <SearchResults
          search={ search }
          results={ results }
          onClose={ this.handleNewSearch }
          onPick={ this.handlePick }
        /> }
      </div> // END .header__container
    );
  }
}
