import React from 'react';

const ContentBlock = (props) => (
  <section id={ props.id } className="content-block">
    { props.left   ? <div className="content-block__left">{props.left}</div>     : null }
    { props.center ? <div className="content-block__center">{props.center}</div> : null }
    { props.right  ? <div className="content-block__right">{props.right}</div>   : null }
  </section>
);

export default ContentBlock;
