import React, { Component } from "react"
import validator from "validator"
import Button from "../Button.js"
import Input from "../Input.js"
import Stripe from "./Stripe.js"
import StripeElements from "./StripeElements"
import { Elements, StripeProvider } from "react-stripe-elements"
import { relativeTimeThreshold } from "moment"
import blacklistedDomains from "./blacklistedDomains"
import CreditReports from "../CreditReports"

import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/style.css"

export default class CheckoutForm extends Component {
  state = {
    isAllValidated: false,
    cvr: this.props.cvr,
    session: this.props.session,
  }

  // isEmailBlacklisted = (maybeEmail) => {
  //   // The domain without TLD (Top Level Domain),
  //   // so we don't need one for every variation.
  //   const domain = maybeEmail?.split("@")?.[1]?.split(".")?.[0]

  //   if (domain == null) {
  //     return false
  //   }

  //   return blacklistedDomains.has(domain)
  // }

  // isEmailAllowed = (maybeEmail) => {
  //   return this.isEmailBlacklisted(maybeEmail)
  // }

  handleSubmit = (event) => {
    event.preventDefault()
  }

  handleFormUpdate = (event) => {
    const { name, value, type, checked } = event.target

    if (type === "checkbox") {
      this.setState({
        [name]: checked,
      })
    } else {
      this.setState({
        [name]: value.trim(),
      })
    }
  }

  handleUpdatePhone = (value, country) => {
    if (this.state.country !== country.countryCode) {
      this.setState({
        country: country.countryCode,
      })
    }
    this.setState({
      user_phone: value,
    })
  }

  success = (data) => {
    this.props.success(data)
  }

  failed = (err) => {
    this.props.failed(err)
  }

  componentDidUpdate = (prevProps, prevState) => {
    // console.log('state', this.state)
    const { user_name, user_email } = this.state

    const user_phone =
      this.state.user_phone && this.state.user_phone.replace(/ /g, "")
    const user_cvr =
      this.state.user_cvr && this.state.user_cvr.replace(/ /g, "")

    const is_user_name_valid = !!user_name && user_name.length > 1
    const is_user_email_valid =
      !!user_email &&
      validator.isEmail(user_email)
      //  &&
      // this.isEmailAllowed(user_email)
    const is_user_phone_valid =
      !!user_phone && validator.isMobilePhone(user_phone)
    const is_user_cvr_valid =
      !!user_cvr && !!parseInt(user_cvr) && user_cvr.length >= 8 && user_cvr.length <= 12

    const isAllValidated =
      is_user_name_valid &&
      is_user_email_valid &&
      is_user_phone_valid &&
      is_user_cvr_valid

    const validation = this.validateEmail()
    if (prevState.isAllValidated == isAllValidated) return
    else this.setState({ isAllValidated })
  }

  validateEmail = () => {
    const isUserEmailValid =
      !!this.state.user_email && validator.isEmail(this.state.user_email)
    if (!isUserEmailValid) {
      // if (this.isEmailAllowed(this.state.user_email)) {
      //   return "all good"
      // } else {
      //   const err = new Error("WTF")
      //   return { error: err, text: "WTF" }
      // }
    // } else {
      const err = new Error("Ugyldig Email")
      return { error: err, text: "Ugyldig Email" }
    }
  }
  getCvrLength = () => {
    if (this.state.country === "dk") {
      return 8
    }
    if (this.state.country === "no") {
      return 8
    }
    if (this.state.country === "se") {
      return 10
    }
    return 8
  }
  validateCVR = () => {
    const length = this.getCvrLength()
    const isCVRValid =
      !!this.state.user_cvr &&
      !!parseInt(this.state.user_cvr) &&
      this.state?.user_cvr?.length >= 8 &&  this.state?.user_cvr?.length <= 12
    if (isCVRValid) {
      return "all good"
    } else {
      const err = new Error("Ugyldigt CVR")
      return { error: err, text: "Ugyldigt CVR" }
    }
  }

  render() {
    return (
      <div className="collapsible collapsible--inverse">
        <span className="line"></span>
        <div className="collapsible__panel">
          <div className="collapsible__content">
            <form onSubmit={this.handleSubmit} onChange={this.handleFormUpdate}>
              <div className="collapsible__body">
                <div className="form-group">
                  <Input
                    classes="purchase_form_controll"
                    name="user_name"
                    type="text"
                    placeholder="Navn"
                    underlined
                    inverse
                  />
                  <Input
                    validation={this.validateEmail()?.text}
                    classes="purchase_form_controll"
                    name="user_email"
                    type="email"
                    placeholder="Email"
                    underlined
                    inverse
                  />
                </div>
                <div className="form-group">
                  <PhoneInput
                    buttonStyle={{ borderTopLeftRadius: "1rem", borderBottomLeftRadius: '1rem' }}
                    containerStyle={{
                      borderRadius: "1rem",
                    }}
                    containerClass="purchase_form_controll_phone"
                    inputStyle={{
                      width: "100%",
                      height: "100%",
                      fontSize: "1.7rem",
                      borderRadius: "1rem",
                      minHeight: "6rem",
                    }}
                    countryCodeEditable={false}
                    country={"dk"}
                    placeholder="Telefon"
                    onlyCountries={["dk", "no", "se"]}
                    value={this.state.user_phone}
                    localization={{ dk: "Danmark", no: "Norge", se: "Sverige" }}
                    onChange={(value, country) =>
                      this.handleUpdatePhone(value, country)
                    }
                  />
                  <Input
                    validation={this.validateCVR()?.text}
                    classes="purchase_form_controll"
                    name="user_cvr"
                    type="text"
                    placeholder="CVR"
                    underlined
                    inverse
                  />
                  {/* <div></div> */}
                  {/* <Input
                    classes="purchase_form_controll"
                    validation={this.validatePhone()?.text}
                    name="user_phone"
                    type="text"
                    placeholder="Telefon"
                    underlined
                    inverse
                  /> */}
                </div>
              </div>
              {!this.state.isAllValidated ? (
                <p className="color--white text-centered text-italic">
                  <small>
                    Udfyld venligst alle ovenstående informationer korrekt.
                  </small>
                </p>
              ) : null}
              <div className="marketing__container">
                <div className="marketing__wrapper">
                  <input name="marketing" type="checkbox" id="allowMarketing" />
                  <label htmlFor="allowMarketing">
                    Jeg giver samtykke til at Risika A/S må kontakte mig med
                    henblik på nyheder, information, salg og gode tilbud.
                  </label>
                </div>
              </div>
            </form>
            <StripeProvider
              className="collapsible__controls"
              apiKey="pk_live_1d3u6rRpZN0CMChbKVuFabPT"
            >
              {/* <StripeProvider className="collapsible__controls" apiKey="pk_test_TYooMQauvdEDq54NiTphI7jx"> */}
              <div className="collapsible__controls">
                {!this.state.isAllValidated ? (
                  <button className="btn btn-primary btn-inverse btn-disabled">
                    Køb rapport 99,- (ekskl. moms)
                  </button>
                ) : (
                  <Elements>
                    <StripeElements
                      success={(data) => this.success(data)}
                      failed={(err) => this.failed(err)}
                      email={this.state.user_email}
                      cvr={this.state.cvr}
                      phone={this.state.user_phone}
                      name={this.state.user_name}
                      userCvr={this.state.user_cvr}
                      allowMarketing={this.state.marketing}
                    />
                  </Elements>
                )}
              </div>
            </StripeProvider>
            <CreditReports />
          </div>
        </div>
      </div>
    )
  }
}
