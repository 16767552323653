import moment from 'moment';
import uuid from 'uuid';
import { localStorageKey } from './app.config.js';

export const updateLocalStorage = (object) => {
  const session = {
    // TODO: change id into session id (aggregate id)
    // TODO: add unique id for each event, independent from session_id
    id: uuid(),                                            // Make sure a session id is always defined
    ...JSON.parse(localStorage.getItem(localStorageKey)),  // Get current session details, eventually override session id
    ...object,                                             // Override with new session information
    timestamp: moment.now()                                // Record last interaction
  }
  session.createdAt = session.createdAt || moment.now();   // Keep track of when new session is created
  localStorage.setItem(localStorageKey, JSON.stringify(session));
  // console.log("Currently in Local Storage:", session);
  return session;
}

export const clearLocalStorage = () => localStorage.removeItem(localStorageKey);

export const validateLocalStorage = () => {
  // TODO: clear local storage if older than 3 days
  const now = moment.now();
  const { createdAt } = updateLocalStorage();
  const timelaps = 3 * 24 * 60 * 60 * 1000; // 3 days in milliseconds
  if ((now - timelaps) > createdAt) clearLocalStorage();
};

/**
 * Returns true if passed int or string is proper CVR format, false otherwise
 * @param Int,String val
 * @returns Boolean
 */
export const validateCVR = (val) => {
  if (!val) return false;
  if (typeof val == 'number') return val > 9999999 && val < 100000000;
  if (typeof val == 'string') {
    let cvr = val.replace(/\s/g, '');
    return (
      cvr.length == 8
      && parseInt(cvr) > 9999999
      && parseInt(cvr) < 100000000
    );
  }
};
