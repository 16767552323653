import React from 'react';

export default (props) => {
  return (
    <footer className="site-footer">

      <div className="site-footer__left">

        <div className="site-footer__logo">
          <a href="https://risika.com"><img src="/static/risika-logo-2019-inverse-800.png"/></a>
        </div>

        <nav className="site-footer__nav">
          <ul className="menu menu--horizontal">
            <li className="menu__item"><a href="https://risika.com/da/om-risika">Hvorfor Risika?</a></li>
            <li className="menu__item"><a href="https://risika.com/da/kontakt">Kontakt</a></li>
            <li className="menu__item"><a href="https://risika.com/da/conditions">Vilkår</a></li>
            <li className="menu__item"><a href="https://risika.com/da/privacy">Persondatapolitik</a></li>
          </ul>
        </nav>
      </div>

      <div className="site-footer__right">

        <div className="site-footer__social">
          <ul className="menu">
            <li><a href="https://facebook.com/risikadk/"><i className="fab fa-facebook"></i></a></li>
            <li><a href="https://linkedin.com/company/11292256/"><i className="fab fa-linkedin"></i></a></li>
            <li><a href="mailto:contact@risika.com"><i className="fas fa-envelope"></i></a></li>
          </ul>
        </div>

        <div className="site-footer__address">
          <p>Kronprinsessegade 26B, 1306 København</p>
          <p>Risika A/S CVR-nr. 37677892</p>
        </div>
        
      </div>

    </footer>
  );
}
