import React from 'react';

const Collapsible = (props) => {
  return props.isOpen && (
    <div className="collapsible">
      <div className="collapsible__panel">
        <div className="collapsible__content">
          { props.header && <div className="collapsible__header">{ props.header }</div> }
          <div className="collapsible__body">
            { props.children }
          </div>
          <div className="collapsible__controls">
            <span
              className="mui-container"
              onClick={ props.onRequestClose }
            ><i className="material-icons">clear</i></span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Collapsible;
