import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import styles from 'react-responsive-carousel/lib/styles/carousel.min.css';

// console.log("Testimonials");

export default (props) => (
  <Carousel
    className="testimonials"
    showIndicators={ false }
    showThumbs={ false }
    showStatus={ false }
    infiniteLoop={ true }
    autoPlay={ true }
    transitionTime={ 600 }
    interval={ 5000 }
  >
    <div className="testimonial">
      <div className="testimonial__thumb">
        <img src="/static/thumb__Kevin-Christensen.jpg"/>
      </div>
      <div className="testimonial__copy">
        <h1>Kevin Christensen</h1>
        <h3>Stifter & Direktør, Friday Group ApS</h3>
        <p>"Vi bruger Risika til at tjekke eksisterende firmakunder, samt nye leads. Vi sælger mange arrangementer til mindre virksomheder, hvor det kan være svært at vurdere, om de er dårlige betalere. Mængden af inkassosager er mindsket betydeligt efter, vi har indført en mere fast kreditpolitik."</p>
      </div>
    </div>
    <div className="testimonial">
      <div className="testimonial__thumb">
        <img src="/static/logo_humac.png"/>
      </div>
      <div className="testimonial__copy">
        {/* <h1>Jesper Holm Madsen</h1> */}
        <h1>Christian</h1>
        {/* <h3>CEO, Humac A/S</h3> */}
        <h3>Key Account Manager, Humac A/S</h3>
        <p>"Hos Humac har vi længe kreditvurderet vores erhvervskunder, men med skiftet til Risika har vi fået et system, der er hurtigere, nemmere at forstå og ikke mindst markant billigere end de, der findes på markedet i dag. Samtidig agerer Risika-teamet i samarbejdet både proaktivt, fleksibelt og yderst professionelt."</p>
      </div>
    </div>
    <div className="testimonial">
      <div className="testimonial__thumb">
        <img src="/static/thumb__Daniel-Alsted-Hansen.jpg"/>
      </div>
      <div className="testimonial__copy">
        <h1>Daniel Alsted Hansen</h1>
        <h3>Medejer & CFO, Elmelund Scandinavia ApS</h3>
        <p>"Når vi skal bygge et unikt snedker-køkken, er det essentielt, at vi kan stole på, vores leverandører leverer elementer til tiden. Risika giver os en hurtig og overskuelig analyse af nuværende og nye leverandører, samt samarbejdspartnere."</p>
      </div>
    </div>

  </Carousel>
);
